import React from 'react';

import { formatCurrency } from 'src/utils';

import { Body, Container, NonReconText, ReconText, Title, TaxPercentage } from './styles';

function ExpensesTabTotals({ totals, grossColor }) {
  const { total_expenses } = totals;
  const { total_incomes } = totals;
  const taxPercentage = total_expenses.gross.reconciled !== 0 ? ((total_expenses.gross.reconciled * -1)/ total_incomes.gross.reconciled) * 100 : 0;
  
  return (
    <Container>
      <Title>Expenses</Title>
      <Body>
          <ReconText color={grossColor}>
            {formatCurrency(total_expenses.gross.reconciled)}
            <TaxPercentage>{Math.round(taxPercentage)}%</TaxPercentage>
          </ReconText>
          <NonReconText>
            {formatCurrency(total_expenses.gross.non_reconciled)}
          </NonReconText>
       </Body>
    </Container>
  );
}

export default ExpensesTabTotals;
