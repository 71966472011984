import styled from 'styled-components';

type Props = {
  color?: string;
};

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #fff;
  padding: 10px 60px;
  border-radius: 6px;
  margin-left: 12px;
  position: relative;
  bottom: 9px;
  height: 93px;
 `;

export const Title = styled.span`
  font-size: 14px;
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10px;
  `;

export const ReconText = styled.span<Props>`
  position: relative;
  font-size: 16px;
  color: ${(props) => props.color};
  display: flex;
  left: -15px;
`;

export const NonReconText = styled.span`
  position: relative;
  font-size: 14px;
  color: #929292;
  display: flex;
  left: -15px;
`;

export const TaxPercentage = styled.div`
  position: absolute;
  right: -65px;
  font-size: 16px;
  color: #209782;
  margin-left: 8px;
  align-items: baseline;
  white-space: nowrap;
`;
