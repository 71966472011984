import React, { ChangeEvent, memo, useState } from 'react';

import ModalStatusPie from 'src/components/common/SalesStatus';
import { useYear } from 'src/hooks';
import { ICustomer } from 'src/models';
import { Col, CustomInput, Tooltip } from 'src/modules';
import {
  CustomerTabs,
  Routes,
  openOnNewTab,
  formatCurrency,
  getFirstLetter,
  capitalize,
  formatDate,
} from 'src/utils';

import { ServiceType } from '../CustomerInformationHeaderPie/styles';
import CustomerProgressPie from '../CustomerProgressPie';
import TableRow from '../TableRow';
import { Avatar } from '../ui';

import {
  ActionsStatusButton,
  AgentName,
  AgentPicture,
  AgentPictureContainer,
  CustomerProgressSection,
  DetailsNameContainer,
  ServiceTypeContainer,
  StatusInfoContainer,
  StatusInfoPie,
  SubscriptionStatus,
  TaxBalance,
} from './styles';

const SUBSCRIPTION_COLOR_BG = {
  ['trial']: '#930303',
  premium: '#209782',
  essential: '#FAD02C',
  expired: '#930303',
};

const SUBSCRIPTION_TEXT = {
  ['trial']: 'Trial',
  premium: 'Premium',
  essential: 'Essential',
  expired: 'Expired Trial',
};

type Props = {
  item: ICustomer;
  index: number;
  onSelectCustomer: any;
  onSelectedCustomers: any;
};

const CustomersRowPie = ({
  item,
  index,
  onSelectCustomer,
  onSelectedCustomers,
}: Props) => {
  const [hovered, setHovered] = useState<number | undefined>(undefined);
  const [modalOpen, setModalOpen] = useState(false);

  const [deleted] = useState(false);

  const [year] = useYear();

  const onSelectTab = (tab: string) => {
    openOnNewTab(
      `${Routes.CUSTOMER_INFORMATION}/?id=${item.id}&tab=${tab}&tax_year=${year}`,
    );
  };

  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };

  return (
    <TableRow
      className={
        'no-gutters py-3 align-items-center ' +
        (deleted && 'opacity-2 pointer-events-none')
      }
    >
      <Col className="col-index text-center" style={{ width: '3%' }}>
        {index + 1}
      </Col>
      <Col className="col-index">
        <CustomInput
          type="checkbox"
          id={`input-${item.id}`}
          name="customSwitch"
          checked={item.selected || false}
          onChange={(e: ChangeEvent) => {
            onSelectCustomer(item);
            onSelectedCustomers(e, item.id);
          }}
        />
      </Col>
      <Col className="col-name" style={{ width: '20%' }}>
        <div className="link" onClick={() => onSelectTab(CustomerTabs.DETAILS)}>
          <div className="d-flex align-items-center">
            <Avatar name={item.full_name} image={item.avatar} />

            <DetailsNameContainer
              onMouseEnter={() => setHovered(item.id)}
              onMouseLeave={() => setHovered(undefined)}
            >
              {item.full_name}
              <br />
              <div className="details-item">
                <b>{item.utr_no}</b>
                <SubscriptionStatus
                  id={`subscription-${item.id}`}
                  background={SUBSCRIPTION_COLOR_BG[item.subscription_plan]}
                >
                  {getFirstLetter(item.subscription_plan ?? '')}
                </SubscriptionStatus>
                <Tooltip
                  target={`subscription-${item.id}`}
                  isOpen={hovered === item.id}
                >
                  {capitalize(item.subscription_plan ?? '')}
                </Tooltip>
              </div>

              <ServiceTypeContainer>
                {item.service_type && (
                  <ServiceType isDIY={item.service_type === 'DIY'}>
                    {item.service_type}
                  </ServiceType>
                )}
              </ServiceTypeContainer>
            </DetailsNameContainer>
          </div>
        </div>
      </Col>

      <Col className="col-progress" style={{ width: '20%' }}>
        <CustomerProgressSection>
          {item.traffic_light.map((traffic, index) => (
            <CustomerProgressPie
              key={`customer-progress-${index}`}
              cardIcon={'/icons/customer-progress-card/service-fee.png'}
              title={traffic.title}
              subtitle={formatDate(traffic?.value)}
              active={!!traffic.value}
              status={traffic.status}
              value={traffic.value ?? ''}
            />
          ))}
        </CustomerProgressSection>
      </Col>

      <Col
        className="col-status d-flex align-items-end"
        style={{
          width: '13%',
        }}
      >
        <StatusInfoContainer>
          {item &&
            item.status_info &&
            item.status_info
              .slice(0, 5)
              .map((info) => (
                <StatusInfoPie key={info.id}>{info.status_label}</StatusInfoPie>
              ))}
          {item && item.status_info && item.status_info.length > 5 && (
            <StatusInfoPie>+ {item.status_info.length - 5} </StatusInfoPie>
          )}
        </StatusInfoContainer>

        <div>
          <ActionsStatusButton onClick={() => toggleModal()}>
            <span className="text">...</span>
          </ActionsStatusButton>
        </div>

        {modalOpen && (
          <ModalStatusPie modalToggle={toggleModal} userId={item.id} />
        )}
      </Col>
      <Col className="col-tax d-flex align-items-end" style={{ width: '8%' }}>
        <TaxBalance>{item.created_date}</TaxBalance>
      </Col>
      <Col
        className="col-tax  d-flex justify-content-center"
        style={{ width: '11%' }}
      >
        <TaxBalance>{formatCurrency(item.refund_amount)}</TaxBalance>
      </Col>

      <Col className="col-action text-center d-flex" style={{ width: '15%' }}>
        <AgentPictureContainer>
          <AgentPicture src={item.assistant_image} alt="agent picture" />
          <AgentName>{item.assistant_name || ''}</AgentName>
        </AgentPictureContainer>
      </Col>
    </TableRow>
  );
};

export default memo(CustomersRowPie);
